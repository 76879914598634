<app-nav>
    <!-- <app-about portfolio-content></app-about> -->
    <router-outlet portfolio-content></router-outlet>
</app-nav>

<!-- <app-home></app-home> -->

<!-- <div class="content">
    <app-about class="about"></app-about>
    <app-contact class="contact"></app-contact>
</div> -->