<form (ngSubmit)="onSubmit()" #f="ngForm">
    <div class="contact" id="user-data" ngModelGroup="userData" #userData="ngModelGroup">
        <div class="contact-header">
            <h1>✉ Contact</h1>
            <p>I would love to hear from you</p>
        </div>
    
        <input class="contact-item input-field" type="text" name="name" placeholder="Name" ngModel>
        
        <input class="contact-item input-field" type="email" name="email" placeholder="Email" ngModel>
    
        <input class="anti-spam" type="text" name="url">
        
        <input class="contact-item input-field" type="text" name="subject" placeholder="Subject" ngModel>
    
        <textarea class="contact-item input-field" name="body" placeholder="Message" ngModel></textarea>
    
        <button class="contact-item submit" [disabled]="f.invalid" type="submit">Send</button>
    </div>
</form>