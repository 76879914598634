<header class="header">
    <h1>
        Hi. I'm Andrew Abril, 
    </h1>
    <h1 class="header-secondary">
        a software developer at                 
    </h1>
    <h1 class="header-secondary">
        <strong>MacKay Shields</strong>.
    </h1>
</header>

<article class="intro">
    <p>
        Hi, my name is Andrew Abril and I currently work at MacKay Shields,
        an asset management firm in New York City. At MacKay, I am the dev 
        lead of the fixed income side of the business where we focus on
    </p>
    <ul>
        <li>Building applications and reporting for the front and middle office</li>
        <li>Integrating internal and vendor applications into existing processes</li>
        <li>Creating firm-wide automation</li>
    </ul>
</article>

<article class="side">
    <p>
        In addition, I manage a real estate investment business with my wife and 
        and enjoy building tools to help identify and analyze properties to fix and flip.
    </p>
</article>

<article class="fun">
    <p>
        For fun, I like to learn new technologies, snowboard, try new recipes in the kitchen and 
        travel the world.  My latest adventure was a 4.5 month trip around South America, Australia, 
        New Zealand and Asia, where I traveled to over 20 cities in 10 countries.
    </p>
</article>
