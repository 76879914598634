<!-- <div class="wrapper">
    <app-nav></app-nav>

    <div class="home">
        <div class="tagline">
            <h1>Solving your business problems</h1>
            <p>Hi, my name is Andrew. Let's talk about solving your business problems.</p>
        </div>
        <img class="tagline-hero" src="../../assets/images/tagline-hero.svg" alt="Developer solving business problems">
    </div>
</div> -->

<app-about></app-about>